import React from "react";
import styled from "styled-components";
import ContactFormOpportunity from "./ContactFormOpportunity";

import { FormPropTypes } from "./Types";

const ContactSectionStyles = styled.section`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 0;
  padding: 6em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  .text-above {
    grid-column: 5 / span 4;
    margin-bottom: 4rem;
    text-align: center;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
    }
  }
`;

const ContactSection = ({ formName }: FormPropTypes) => {
  return (
    <ContactSectionStyles>
      <div className="text-above">
        <h1>Get In Touch</h1>{" "}
        <p>
          Have a cool project or a work opportunity? <br />
          Send me a message, and I’ll get back to you as soon as possible.
        </p>
      </div>
      <ContactFormOpportunity formName={formName} />
    </ContactSectionStyles>
  );
};

export default ContactSection;
