import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ContactSection from "../components/ContactSection";

const ContactPageStyles = styled.main`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, var(--column-width))) 1fr;
  gap: 0 2rem;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }
`;

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contact" />

      <ContactPageStyles>
        <ContactSection formName="contactPageForm" />
      </ContactPageStyles>
    </Layout>
  );
};

export default ContactPage;
