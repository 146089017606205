import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import AnimatedButton from "./AnimatedButton";

import { FormPropTypes } from "./Types";

const ContactFormOpportunityStyles = styled.div`
  grid-column: 1 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  form {
    width: 100%;
    grid-column: 3 / span 8;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
    }

    .form-top-row {
      display: flex;
      justify-content: space-between;
      .field {
        width: 48%;
      }
    }

    input,
    textarea {
      position: relative;
      display: inline-block;
      width: 100%;
    }

    .field {
      position: relative;
      margin-bottom: 1.6em;
      &.no-margin {
        margin-bottom: 0;
      }
    }

    textarea {
      height: 6em;
      resize: vertical;
      max-height: 240px;
      min-height: 70px;
    }

    label span {
      position: absolute;
      display: inline-block;
      margin: 0 auto;
      padding: 5px 0 5px 5px;
      top: 0;
      z-index: 1;
      transition: all 0.5s ease-in-out;
      color: var(--color-gray-600);
    }

    input {
      height: 38px;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea,
    input[type="password"] {
      font-size: 1em;
      padding: 5px 0 5px 5px;
      margin-bottom: 0.5em;
      z-index: 6;
      background: transparent;
      border: none;
      border-bottom: 1px solid var(--color-black);
      color: var(--color-black);
      transition: border 300ms;
      &.alert {
        border-bottom: 1px solid var(--color-alert-300);
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px var(--color-gray-100) inset !important;
      -webkit-box-shadow: 0 0 0 30px var(--color-gray-100) inset !important;
    }

    .required-alert {
      border: 1px solid var(--color-alert-300);
      color: var(--color-white);
      padding: 0.2em 1em;
      p {
        margin: 0;
        font-size: var(--font-small-size);
      }
    }

    .filled {
      top: -28px;
      font-size: 0.9em;
    }

    .hidden {
      display: none;
    }

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline-color: var(--color-gray-400);
    }

    .submit-btn {
      display: flex;
      justify-content: center;
    }
  }
`;

const ContactFormOpportunity = ({ formName }: FormPropTypes) => {
  const [requiredAlert, setRequiredAlert] = useState(false);

  const [nameFocused, setNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [messageFocused, setMessageFocused] = useState(false);

  const [nameValue, setNameValue] = useState<string | null>(null);
  const [emailValue, setEmailValue] = useState<string | null>(null);
  const [messageValue, setMessageValue] = useState<string | null>(null);

  const [formFields, setFormFields] = useState({});

  const onNameFocus = () => setNameFocused(true);
  const onNameBlur = () => setNameFocused(false);

  const onEmailFocus = () => setEmailFocused(true);
  const onEmailBlur = () => setEmailFocused(false);

  const onMessageFocus = () => setMessageFocused(true);
  const onMessageBlur = () => setMessageFocused(false);

  const handleChange = (
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>
  ) => {
    const el = e.target as HTMLInputElement;
    if (el.name === "name") {
      setNameValue(el.value);
    } else if (el.name === "email") {
      setEmailValue(el.value);
    } else if (el.name === "message") {
      setMessageValue(el.value);
    }
    setFormFields({ ...formFields, [el.name]: el.value });
  };

  const encode = (data: Record<string, any>) => {
    return Object.keys(data)
      .map(
        (key: string) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const el = e.target as HTMLInputElement;

    if (!nameValue || !emailValue || !messageValue) {
      setRequiredAlert(true);
    } else {
      setRequiredAlert(false);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": el.getAttribute("name"),
          ...formFields,
        }),
      })
        .then(() => navigate("/thank-you/"))
        .catch((error) => alert(error));
    }
  };

  return (
    <ContactFormOpportunityStyles>
      <form
        className="contact-form-home"
        name={`${formName}-opportunity`}
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-check-field"
        onSubmit={handleSubmit}
      >
        <input
          type="hidden"
          name={`${formName}-opportunity`}
          value="opportunity"
        />
        <div className="form-top-row">
          <div className="field">
            <label>
              <span className={nameFocused || nameValue ? "filled" : ""}>
                Name
              </span>
              <input
                type="text"
                name="name"
                onFocus={onNameFocus}
                onBlur={onNameBlur}
                onChange={handleChange}
                className={!nameValue && requiredAlert ? "alert" : ""}
              />
            </label>
          </div>

          <div className="field">
            <label>
              <span className={emailFocused || emailValue ? "filled" : ""}>
                Email
              </span>
              <input
                type="email"
                name="email"
                onFocus={onEmailFocus}
                onBlur={onEmailBlur}
                onChange={handleChange}
                className={!emailValue && requiredAlert ? "alert" : ""}
              />
            </label>
          </div>
        </div>
        <div className="field no-margin">
          <label>
            <span className={messageFocused || messageValue ? "filled" : ""}>
              Message
            </span>
            <textarea
              name="message"
              onFocus={onMessageFocus}
              onBlur={onMessageBlur}
              onChange={handleChange}
              className={!messageValue && requiredAlert ? "alert" : ""}
            ></textarea>
          </label>
        </div>

        <p className="hidden">
          <label>
            Don’t fill this out if you’re human:{" "}
            <input name="bot-check-field" />
          </label>
        </p>
        {requiredAlert && (
          <div className="required-alert">
            <p>Please fill out all fields</p>
          </div>
        )}
        <div className="submit-btn">
          <AnimatedButton text="Send" />
        </div>
      </form>
    </ContactFormOpportunityStyles>
  );
};

export default ContactFormOpportunity;
